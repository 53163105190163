import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import { useNavigate } from 'react-router-dom';
import { AREA_PREFIX, BANNER_IMG, COUPON_PREFIX, COUPON_TYPE, TOAST_MSG_TYPE,COUPON_AMOUNT_TYPE,getConstantData } from '../../utils/Constants';
import { routeService } from '../../Services/RouteService';
import { bannerService,merchantNamesList } from '../../Services/BannerService';
import { useMerchantProductList } from '../../hooks/ProductHooks';
import { useAdminCateogryList } from '../../hooks/CategoryHooks';
import useAuth from '../../hooks/useAuth'
// import Select from 'react-select';
import { Select,Select as AntSelect,DatePicker } from 'antd';
import moment from 'moment';
import ImageUpload from '../ImageUpload';
import { BANNER_LINK_TYPE } from '../../utils/Constants';
const TYPESOFREWARDS = [
    {label: "Reward Point ", value: 1},
    {label: "Direct Discount", value: 0},
]
const initialState = {id: 0, coupon_max_amount:0,is_rewardpoints:0,coupon_code: "", coupon_image: {server: ""}, is_percent:false, coupon_amount:0, new_user:false, min_purchase_amount:0, total_number_coupons: 1, coupon_desc: "", product_id: undefined, category_id: 0, start_date: undefined, end_date: undefined,dataLoaded:false,coupon_type: "",per_user:1,coupon_title: "",coupon_merchants:[]}
const BannerForm = (props) => {
    const navigate = useNavigate()
	 let { auth } = useAuth()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
	const [params, setParams] = useState({product_id:0,search:"",paginated: true, limit: 10, offset: 0, search: "", field: "id", sort: "ASC", counter: 0,merchant_id:0,store_id:0,product_brand:"",product_supplier:"",statusFilter:null})
	const {productData,total} = useMerchantProductList(axiosPrivate, params)
	const {categoryData} = useAdminCateogryList(axiosPrivate, params)
    const [formState, setFormState] = useState({...initialState})
    const [disabled, setDisabled] = useState(false)
	const [merchantList, setMerchantList] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
    useEffect(() => {
        if(props.coupon_code && !formState.dataLoaded) {
            routeService.couponDetailsByCouponCode(axiosPrivate, props.coupon_code).then(response => {
                if(response.data.couponDetailsByCouponCode) {
                    const res = response.data.couponDetailsByCouponCode
					console.log("product_id",res);
                    setFormState((prev) => {
                        return {
                            ...prev,
                            id: res.id,
							coupon_type: res.coupon_type,
                            coupon_code: res.coupon_code,
                            is_percent: res.is_percent,
                            start_date: res.start_date && moment.unix(Number(res.start_date)),
                            end_date: res.end_date && moment.unix(Number(res.end_date)),
                            coupon_amount: res.coupon_amount || 0,
                            new_user: res.new_user,
                            min_purchase_amout: res.min_purchase_amount ? res.min_purchase_amount : 0,
                            total_number_coupons: res.total_number_coupons,
                            coupon_desc: res.coupon_desc,
							product_id: res.product_id+"",
							category_id: res.category_id+"",
                            dataLoaded: true,
							per_user: res.per_user,
							coupon_title: res.coupon_title,
							coupon_max_amount: res.coupon_max_amount,
							is_rewardpoints: res.is_rewardpoints,
							coupon_title: res.coupon_title,
							coupon_merchants: res.coupon_merchants,
							coupon_image: {server: res.coupon_image ? res.coupon_image : ""},
                        }
                    })
                    setParams((prev) => {
                        return {
                            ...prev,
                            store: res.store_id,
							product_id: res.product_id
                        }
                    })
                    setShowLoader(false)
                }else {
                    navigate(`${COUPON_PREFIX}`)
                }
            })    
        }else if(props.id == undefined || props.id == "0"){
            setShowLoader(false)
        }
    }, [props.id])
    
    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        const apiPromise = bannerService.merchantNamesList(axiosPrivate)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
			setMerchantList(res.data.merchantNamesListCMS)
		});
    }, [props.crud])

    const onChangeField = (e) => {
		
        const name = e.target.name
        const value = e.target.value
		console.log("Value: ", name)
		console.log("Value: ", value)
        setFormState({...formState, [name]: value})
    }
    const onChangeBannerType = (value) => {
		setFormState({...formState, coupon_type: value});
    }
	const onChangeONLYNEW=(value)=>{
		setFormState({...formState, new_user: value});
	}
    const onChangeBannerLinkType = (value) => {
		setFormState({...formState, is_percent: value})
    }
	const onChangeREWARDPoints = (value) => {
		setFormState({...formState, is_rewardpoints: value})
	}
    const onChangeStartDate = (value, dateString) => {
        setFormState({...formState, start_date: moment(dateString)})
    }
    const onChangeEndDate = (value, dateString) => {
        setFormState({...formState, end_date: moment(dateString)})
    }
    const onChangeProduct = (e) => {
		setFormState({...formState, product_id: e})		
    }
	const onChangeCategory = (e) => {
		setFormState({...formState, category_id: e})		
    }
	const onBusinessTypeChange = (e) => {
        setFormState({...formState, coupon_merchants: [...e]})
    }
	
	const handleProductSearch = (value: string) => {
		if(value.length>2){
		console.log("handleProductSearch",value)
		setParams({...params, search: value,product_id:0})
		}
	}
	

    const submitHandler = (e) => {
        e.preventDefault()
        if(!formState.coupon_type) {
            showToast("Please select Coupon Type Title", TOAST_MSG_TYPE.ERROR)
            return
        }
        
        if(!formState.coupon_code) {
            showToast("Please enter coupon code", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.start_date) {
            showToast("Please Fill Start Date", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.end_date) {
            showToast("Please Fill End Date", TOAST_MSG_TYPE.ERROR)
            return
        }
        let startTime = moment(formState.start_date)
        let endTime = moment(formState.end_date)
		let paramData ={
			id: formState.id,
			coupon_title: formState.coupon_title,
			coupon_type: formState.coupon_type,
			coupon_code: formState.coupon_code,
			is_percent: formState.is_percent,
			start_date: Number(formState.start_date/1000),
			end_date: Number(formState.end_date/1000),
			coupon_amount: formState.coupon_amount || 0,
			new_user: formState.new_user,
			min_purchase_amout: parseFloat(formState.min_purchase_amout),
			total_number_coupons: Number(formState.total_number_coupons),
			coupon_desc: formState.coupon_desc,
			product_id: formState.product_id ? formState.product_id : 0,
			category_id: formState.category_id,
			per_user: Number(formState.per_user),
			coupon_merchants: formState.coupon_merchants,
			coupon_max_amount: parseFloat(formState.coupon_max_amount),
			is_rewardpoints: Number(formState.is_rewardpoints),
		}
		console.log(paramData)
		
        const apiPromise = routeService.createUpdateCoupon(axiosPrivate, paramData, formState.coupon_image.server)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.createUpdateCoupon) {
                showToast(props.crud === "create" ? "Coupon Added Successfully!" : "Coupon Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({...initialState})
                navigate(`${COUPON_PREFIX}`)
            }
        })
    }

    return (
        <>
        {
            showLoader ? <p>Loading...</p> :
            <form>
                <div className='row mb-4'>
				    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Coupon Title*</label>
                            <input type="text" className='form-control' name='coupon_title' value={formState.coupon_title} onChange={onChangeField} />
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Coupon Type*</label>
                            <Select 
                                showSearch={true}
                                className='select col-md-12'
                                value={formState.coupon_type}
                                options={COUPON_TYPE}
                                onChange={onChangeBannerType}
                            />
                        </div>
                    </div>
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>Select Merchant*</label>
                            <AntSelect 
                                mode='multiple'
                                className='select col-md-12'
                                value={formState.coupon_merchants}
                                options={merchantList}
                                onChange={onBusinessTypeChange}
                                optionFilterProp={"business_name"}
                                fieldNames={{label: "business_name", value: "merchant_id"}}
                                isDisabled={disabled}
                            />
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Coupon Code*</label>
                            <input type="text" className='form-control' name='coupon_code' value={formState.coupon_code} onChange={onChangeField} />
                        </div>
                    </div>
					{
                   formState.coupon_type==="Free_Product" ? '' :
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Coupon Amount/Reward Points*</label>
                            <input type="number" className='form-control' name='coupon_amount' value={formState.coupon_amount} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					}
					{
                   formState.coupon_type==="Free_Product" ? '' :
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Max Discount Amount/Reward Points*</label>
                            <input type="number" className='form-control' name='coupon_max_amount' value={formState.coupon_max_amount} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					}
					{
                   formState.coupon_type==="Free_Product" ? '' :
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>Discount/Reward Points  ? </label>
                            <Select  
                                className='select col-md-12'
                                value={formState.is_rewardpoints}
                                options={TYPESOFREWARDS}
                                onChange={onChangeREWARDPoints}
                            />
                        </div>
                    </div>
					}
					{
                   formState.coupon_type==="Free_Product" ? '' :
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>Is Per%</label>
                            <Select 
                                className='select col-md-12'
                                value={formState.is_percent}
                                options={COUPON_AMOUNT_TYPE}
                                onChange={onChangeBannerLinkType}
                            />
                        </div>
                    </div>
					}					
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>Only For New Users</label>
                            <Select  
                                showSearch={true}
                                className='select col-md-12'
                                value={formState.new_user}
                                options={COUPON_AMOUNT_TYPE}
                                onChange={onChangeONLYNEW}
                            />
                        </div>
                    </div>
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>Min purchase amount*</label>
                            <input type="number" className='form-control' name='min_purchase_amout' value={formState.min_purchase_amout} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>total number coupons*</label>
                            <input type="number" className='form-control' name='total_number_coupons' value={formState.total_number_coupons} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>Coupon Per user</label>
                            <input type="number" className='form-control' name='per_user' value={formState.per_user} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Start Date*</label>
                            <DatePicker 
                                placeholder="Select Start Date" 
                                className="form-control" 
                                onChange={onChangeStartDate} 
                                value={formState.start_date || ""}
                                disabledDate={(current) => {
                                    return moment().add(-1, 'days')  >= current
                                }}
                            />
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>End Date*</label>
                            <DatePicker 
                                placeholder="Select End Date" 
                                className="form-control" 
                                onChange={onChangeEndDate} 
                                value={formState.end_date || ""}
                                disabledDate={(current) => {
                                    return moment().add(-1, 'days')  >= current
                                    }}
                            />
                        </div>
                    </div>
					{
                    (formState.coupon_type == "Free_Product" || formState.coupon_type == "Discounted_Product") &&
                    <div className='col-md-6'>
						<div className='form-group'>
							<label>Select Products</label>
							<Select 
								showSearch={true}
								className='select col-md-12'
								value={formState.product_id}
								options={productData}
								onChange={onChangeProduct}
								onSearch={handleProductSearch}
								optionFilterProp={"product_title"}
								fieldNames={{label: "product_title", value: "id"}}
							/>
						</div>
					</div>
					}
					{
                    (formState.coupon_type == "Category") &&
                    <div className='col-md-6'>
						<div className='form-group'>
							<label>Select Category</label>
							<Select 
								showSearch={true}
								className='select col-md-12'
								value={formState.category_id}
								options={categoryData}
								onChange={onChangeCategory}
								optionFilterProp={"category_title"}
								fieldNames={{label: "category_title", value: "id"}}
							/>
						</div>
					</div>
					}
                </div>
                    
				<div className='row mb-4'>
					<div className='col-md-9'>
					<div className='form-group'>
                                <label>Desc:</label>
                                <textarea className='form-control' rows={4} value={formState.coupon_desc} name='coupon_desc' onChange={onChangeField}></textarea>
                            </div>
					</div>
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>Coupon Image</label>
                            <ImageUpload
                                fileName={formState.coupon_image.server}
                                fileList={
                                formState.coupon_image.server
                                    ? [
                                        {
                                        uid: formState.coupon_image,
                                        url:"local" in formState.coupon_image ? `${formState.coupon_image.local.url}` : `${BANNER_IMG}/${formState.coupon_image.server}`
                                        }
                                    ]
                                    : []
                                }
                                getFile={file => {
                                    setFormState({...formState, coupon_image: file});
                                }}
                                onRemove={() => setFormState({...formState, coupon_image: {server: ""}})}
                                crud={props.crud}
                            />
                        </div>
                    </div>
				</div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className="btn btn-primary btn-width mx-auto d-block" onClick={submitHandler}>Save</button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default BannerForm