import { useContext, useEffect, useState } from "react"
import { Select } from 'antd';
import { CommonContext } from '../../context/CommonContext';
import { useDashboardData } from '../../hooks/DashboardHooks'
import useAuth from "../../hooks/useAuth";
import StoreSelector from "../StoreSelector";
import { API_BASE_URL, YEAR_LIST } from '../../utils/Constants';
import {BarChart,Bar, Cell,ResponsiveContainer,
    LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
  } from 'recharts';
import { routeService } from '../../Services/RouteService';

const barchartdata = [
    { y: '2006', "Total Income" : 100 },
    { y: '2007', "Total Income" : 75 },
    { y: '2008', "Total Income" : 50 },
    { y: '2009', "Total Income" : 75 },
    { y: '2010', "Total Income" : 50 },
    { y: '2011', "Total Income" : 75 },
    { y: '2012', "Total Income" : 100 }
];
const monthsOrder = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const SuperAdmin = (props) => {
    const { userInfo } = useAuth()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [store, setStoreID] = useState({id: 0, store_name: "All", store_id: 0,year_filter:2025})
    const {dashboardData, loading} = useDashboardData(axiosPrivate, store)
    const [cartProducts, setCartProducts] = useState([])
	const [fYear, setfYear] = useState(2025)
	
	useEffect(() => {
		if(dashboardData.earning_yearly){
			var fleetData=dashboardData.earning_yearly
            var newdata=fleetData.sort((a, b) => monthsOrder.indexOf(a.month_name) - monthsOrder.indexOf(b.month_name));
			setCartProducts(newdata)
        }
    }, [loading])
    const changeStore = (store) => {
        setStoreID(store)
    }
	const onBusinessYear = (e) => {
		setStoreID({...store,year_filter:e})
	}
const menuData = (e) => {
        e.preventDefault()
		let params={}
        const apiPromise = routeService.getHighDemandExcel(axiosPrivate,params)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            const data = res.data.excelHighinDemandProducts
            if(data.status == "success") {
                const filePath = data.path.replace("./", "")
                console.log(filePath)
                const link = document.createElement("a");
                link.download = `giftCards.xlsx`;
                link.href = `${API_BASE_URL}${filePath}`;
                link.click();
            }
        })
    }
	const menuData2 = (e) => {
        e.preventDefault()
		let params={}
        const apiPromise = routeService.getHighPricnignExcel(axiosPrivate,params)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            const data = res.data.excelHighinEarningProducts
            if(data.status == "success") {
                const filePath = data.path.replace("./", "")
                console.log(filePath)
                const link = document.createElement("a");
                link.download = `giftCards.xlsx`;
                link.href = `${API_BASE_URL}${filePath}`;
                link.click();
            }
        })
    }
    return (
        <div className="content container-fluid dashboard-cards">
            {
                loading ? <p>Loading...</p> :
                <>
                    {
                        userInfo.account_type === 1 ? 
                        null : 
                        <div className="row mb-4">
                            <div className="col-md-6">
                                <StoreSelector selectedStore={store} changeStore={changeStore}/>
                            </div>
                        </div>
                    }
                    <div className="row">
                        <div className="col-md-12">
                            <h3>All Time Data</h3>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div className="card dash-widget">
                                <div className="card-body">
                                    <span className="dash-widget-icon"><i className="fa fa-shopping-bag" /></span>
                                    <div className="dash-widget-info">
                                        <h3>{dashboardData.all_time_total_transactions}</h3>
                                        <span>Total Orders</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div className="card dash-widget">
                                <div className="card-body">
                                    <span className="dash-widget-icon"><i className="fa fa-dollar" /></span>
                                    <div className="dash-widget-info">
                                        <h3>{dashboardData.all_time_total_transactions_amount}</h3>
                                        <span>Transaction Amount</span>
                                    </div>
                                </div>
                            </div>
                        </div>
						<div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div className="card dash-widget">
                                <div className="card-body">
                                    <span className="dash-widget-icon"><i className="fa fa-repeat" /></span>
                                    <div className="dash-widget-info">
                                        <h3>{dashboardData.orders_duplicates}</h3>
                                        <span>Total Repeat Orders</span>
                                    </div>
                                </div>
                            </div>
                        </div>
						<div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div className="card dash-widget">
                                <div className="card-body">
                                    <span className="dash-widget-icon"><i className="fa fa-users" /></span>
                                    <div className="dash-widget-info">
                                        <h3>{dashboardData.total_customers}</h3>
                                        <span>Total Customers</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <h3>Todays Data</h3>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div className="card dash-widget">
                                <div className="card-body">
                                    <span className="dash-widget-icon"><i className="fa fa-shopping-basket" /></span>
                                    <div className="dash-widget-info">
                                        <h3>{dashboardData.total_sales_today}</h3>
                                        <span>Today Orders</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div className="card dash-widget">
                                <div className="card-body">
                                    <span className="dash-widget-icon"><i className="fa fa-shopping-cart" /></span>
                                    <div className="dash-widget-info">
                                        <h3>{dashboardData.total_delivery_today}</h3>
                                        <span>Deliveries</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div className="card dash-widget">
                                <div className="card-body">
                                    <span className="dash-widget-icon"><i className="fa fa-truck" /></span>
                                    <div className="dash-widget-info">
                                        <h3>{dashboardData.total_shipped_delivery_today}</h3>
                                        <span>Shipped Deliveries</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-6 col-lg-6 col-xl-3">
                            <div className="card dash-widget">
                                <div className="card-body">
                                    <span className="dash-widget-icon"><i className="fa fa-handshake-o" /></span>
                                    <div className="dash-widget-info">
                                        <h3>{dashboardData.total_completed_delivery_today}</h3>
                                        <span>Completed Deliveries</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="row">
									<div className="card card-table flex-fill">
										<div className="card-header">
										<div className="row">
											<div className="col-md-9">
											  <h3 className="card-title mb-0">Total Revenue</h3> 
											</div>
											<div className="col-md-3">                                    
											<div className='form-group'>
												<Select 
													className='select col-md-12'
													value={store.year_filter}
													options={YEAR_LIST}
													onChange={onBusinessYear}
												/>
											</div>
											</div>
										</div>
									</div>
								</div>
                                <div className="col-md-12 text-center">
                                    <div className="card">
                                        <div className="card-body">
                                            {/* <div id="bar-charts" /> */}
                                            <ResponsiveContainer width='100%' height={300}>
                                                <BarChart
                                                    data={cartProducts}
                                                    margin={{
                                                        top: 5, right: 5, left: 5, bottom: 5,
                                                    }}
                                                >
                                                    <CartesianGrid />
                                                    <XAxis dataKey="month_name" />
                                                    <YAxis />
                                                    <Tooltip />
                                                    <Legend />
                                                    <Bar dataKey="total_amount" fill="#ff9b44"/>
                                                </BarChart>
                                            </ResponsiveContainer>                  
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 d-flex">
                            <div className="card card-table flex-fill">
                                <div className="card-header">
								<div className="row">
								    <div className="col-md-6">
                                      <h3 className="card-title mb-0">High in Demand Products</h3> 
									</div>
									<div className="col-md-6">                                    
									  <a className="btn btn-success" href="#" onClick={(e) => menuData(e)}>Download All Report</a> 
									</div>
								</div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-nowrap custom-table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Product Name</th>
                                                    <th>Store</th>
                                                    <th>Total Sold</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dashboardData.high_in_demands.map((product) => {
                                                        return (
                                                            <tr>
                                                                <td>{product.product_title}</td>
                                                                <td>{product.store_name}</td>
                                                                <td>{product.total_sold}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex">
                            <div className="card card-table flex-fill">
                                <div className="card-header">
                                    <h3 className="card-title mb-0">Low in Demand Products</h3>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-nowrap custom-table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Product Name</th>
                                                    <th>Store</th>
                                                    <th>Total Sold</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dashboardData.low_in_demands.map((product) => {
                                                        return (
                                                            <tr>
                                                                <td>{product.product_title}</td>
                                                                <td>{product.store_name}</td>
                                                                <td>{product.total_sold}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex">
                            <div className="card card-table flex-fill">
                                <div className="card-header">
								<div className="row">
								    <div className="col-md-6">
                                      <h3 className="card-title mb-0">Highest Earning Products</h3> 
									</div>
									<div className="col-md-6">                                    
									  <a className="btn btn-success" href="#" onClick={(e) => menuData2(e)}>Download All Report</a> 
									</div>
								</div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-nowrap custom-table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Product Name</th>
                                                    <th>Store</th>
                                                    <th>Total Sold Amount($)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dashboardData.highest_earning.map((product) => {
                                                        return (
                                                            <tr>
                                                                <td>{product.product_title}</td>
                                                                <td>{product.store_name}</td>
                                                                <td>{product.total_sold_amount}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 d-flex">
                            <div className="card card-table flex-fill">
                                <div className="card-header">
                                    <h3 className="card-title mb-0">Lowest Earning Products</h3>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <table className="table table-nowrap custom-table mb-0">
                                            <thead>
                                                <tr>
                                                    <th>Product Name</th>
                                                    <th>Store</th>
                                                    <th>Total Sold Amount($)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dashboardData.lowest_earning.map((product) => {
                                                        return (
                                                            <tr>
                                                                <td>{product.product_title}</td>
                                                                <td>{product.store_name}</td>
                                                                <td>{product.total_sold_amount}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {/* <div className="page-header">
                <div className="row">
                    <div className="col-sm-12">
                        <h3 className="page-title">Welcome Admin!</h3>
                        <ul className="breadcrumb">
                            <li className="breadcrumb-item active">Dashboard</li>
                        </ul>
                    </div>
                </div>
            </div> */}
        </div>
    )
}
export default SuperAdmin