import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import { useNavigate } from 'react-router-dom';
import { LICENSE_IMG, TEAM_PREFIX, TOAST_MSG_TYPE, getConstantData } from '../../utils/Constants';
import { Select as AntSelect } from 'antd';
import Select from 'react-select';
import useAuth from '../../hooks/useAuth';
import { useFleetList } from '../../hooks/FleetHooks';
import ImageUpload from '../ImageUpload';
import { teamService } from '../../Services/TeamService';
import countryCodes from '../../utils/countryCodes';

const initialState = {id: 0, country_phone_code: "", department_id: "", name: "", phone_number: "", emergency_phoneno: "", hourly_rate: 0.0, address: {}, notes: "", fleet_id: "", license_upload: {server: ""}, license_back_upload: {server: ""},login_password: "" }
const TeamForm = (props) => {
    const navigate = useNavigate()
    const { auth } = useAuth()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [params, setParams] = useState({paginated: false, limit: 0, offset: 0, search: "", field: "id", sort: "ASC"})
    const {fleetData, loading, error, total} = useFleetList(axiosPrivate, params)
    const [formState, setFormState] = useState({...initialState})
    const [disabled, setDisabled] = useState(false)
    const [showLoader, setShowLoader] = useState(false)

    useEffect(() => {
        if(props.member_id) {
            teamService.getTeamMember(axiosPrivate, props.member_id).then(res => {
                if(res.data.teamMemberDetailsById) {
                    const data = res.data.teamMemberDetailsById.member_details
					console.log(data)
                    setFormState({
                        ...data,
                        country_phone_code: countryCodes.find((code) => code.dial_code == data.country_phone_code),
                        license_upload: {server: data.license_upload ? data.license_upload : ""},
                        license_back_upload: {server: data.license_back_upload ? data.license_back_upload : ""},
						login_password: data.verification_data && data.verification_data.login_otp ? data.verification_data.login_otp : 123456
                    })
                    setShowLoader(false)
                }else {
                    navigate(`${TEAM_PREFIX}`)
                }
            })
        }else {
            setShowLoader(false)
        }
    }, [props.member_id])
    console.log(formState)
    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])

    const onChangeField = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }
    
    const onChangeDepartment = (value) => {
        setFormState({...formState, department_id: value})
    }
    const onCountryChange = (value) => {
        setFormState({...formState, country_phone_code: value})
    }
    const onChangeFleet = (value) => {
        setFormState({...formState, fleet_id: value})
    }

    const validateForm = () => {
        if(!formState.department_id) {
            showToast("Please Choose Department", TOAST_MSG_TYPE.ERROR)
            return false
        }
        if(!formState.name) {
            showToast("Please Fill Staff Name", TOAST_MSG_TYPE.ERROR)
            return false
        }
        if(!formState.country_phone_code) {
            showToast("Please Choose Country Phone Code", TOAST_MSG_TYPE.ERROR)
            return false
        }
        if(!formState.phone_number) {
            showToast("Please Fill Staff Phone", TOAST_MSG_TYPE.ERROR)
            return false
        }
        if(!formState.hourly_rate) {
            showToast("Please Fill Hourly Wedge", TOAST_MSG_TYPE.ERROR)
            return false
        }
        if(isNaN(Number(formState.hourly_rate))) {
            showToast("Please Fill Correct Hourly Wedge", TOAST_MSG_TYPE.ERROR)
            return false
        }
        if(formState.department_id == 1) {
            if(!formState.fleet_id) {
                showToast("Please Choose Driver Fleet", TOAST_MSG_TYPE.ERROR)
                return false
            }
            if(!formState.license_upload.server) {
                showToast("Please Choose Image For License", TOAST_MSG_TYPE.ERROR)
                return false
            }
            if(!formState.license_back_upload.server) {
                showToast("Please Choose Image For License Back Side", TOAST_MSG_TYPE.ERROR)
                return false
            }
        }
        return true
    }

    const submitHandler = (e) => {
        e.preventDefault()
        if(!validateForm()) {
            return
        }
        let param = {
            id: formState.id,
            department_id: Number(formState.department_id),
            name: formState.name,
            phone_number: formState.phone_number,
            emergency_phoneno: formState.emergency_phoneno,
            hourly_rate: Number(formState.hourly_rate),
            address: formState.address,
            notes: formState.notes,
            country_phone_code: formState.country_phone_code.dial_code,
            fleet_id: Number(formState.fleet_id),
			login_password:  Number(formState.login_password)
        }
		console.log(formState)
        
        const apiPromise = teamService.addNewTeamMember(axiosPrivate, param, formState.license_upload.server, formState.license_back_upload.server)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.createUpdateTeam) {
                showToast(props.crud === "create" ? "Member Added Successfully!" : "Member Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({...initialState})
                navigate(`${TEAM_PREFIX}`)
            }
        })
    }
    
    return (
        <>
        {
            showLoader ? <p>Loading...</p> :
            <form>
                <div className='row mb-4'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Department*</label>
                            <AntSelect 
                                showSearch={true}
                                className='select col-md-12'
                                value={formState.department_id}
                                options={getConstantData("TEAM_DEPARTS", auth.constants)}
                                onChange={onChangeDepartment}
                                optionFilterProp={"value"}
                                fieldNames={{label: "value", value: "id"}}
                            />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Name*</label>
                            <input type="text" className='form-control' name='name' value={formState.name} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Country*</label>
                            <Select 
                                className="select"
                                value={formState.country_phone_code}
                                options={countryCodes}
                                onChange={onCountryChange}
                                getOptionValue={(option) => `${option.dial_code}`}
						        getOptionLabel={(option) => `${option.name} (${option.dial_code})`}
                                isDisabled={disabled}
                            >
                            </Select>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Phone*</label>
                            <input type="text" className='form-control' name='phone_number' value={formState.phone_number} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					<div className='col-md-3'>
                        <div className='form-group'>
                            <label>Login Password</label>
                            <input type="password" className='form-control' name='login_password' value={formState.login_password} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Emergency Phone</label>
                            <input type="text" className='form-control' name='emergency_phoneno' value={formState.emergency_phoneno} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Hourly Wedge*</label>
                            <input type="text" className='form-control' name='hourly_rate' value={formState.hourly_rate} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    {
                        formState.department_id == 1 ? 
                        <>
                            <div className='col-md-3'>
                                <div className='form-group'>
                                    <label>Fleet*</label>
                                    <AntSelect 
                                        showSearch={true}
                                        className='select col-md-12'
                                        value={formState.fleet_id}
                                        options={fleetData}
                                        onChange={onChangeFleet}
                                        optionFilterProp={"fleet_licence_number"}
                                        fieldNames={{label: "fleet_licence_number", value: "id"}}
                                    />
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='form-group'>
                                    <label>Driver License Front Image*</label>
                                    <ImageUpload
                                        fileName={formState.license_upload.server}
                                        fileList={
                                        formState.license_upload.server
                                            ? [
                                                {
                                                uid: formState.license_upload,
                                                url:"local" in formState.license_upload ? `${formState.license_upload.local.url}` : `${LICENSE_IMG}/${formState.license_upload.server}`
                                                }
                                            ]
                                            : []
                                        }
                                        getFile={file => {
                                            setFormState({...formState, license_upload: file});
                                        }}
                                        onRemove={() => setFormState({...formState, license_upload: {server: ""}})}
                                        crud={props.crud}
                                    />
                                </div>
                            </div>
                            <div className='col-md-3'>
                                <div className='form-group'>
                                    <label>Driver License Back Image*</label>
                                    <ImageUpload
                                        fileName={formState.license_back_upload.server}
                                        fileList={
                                        formState.license_back_upload.server
                                            ? [
                                                {
                                                uid: formState.license_back_upload,
                                                url:"local" in formState.license_back_upload ? `${formState.license_back_upload.local.url}` : `${LICENSE_IMG}/${formState.license_back_upload.server}`
                                                }
                                            ]
                                            : []
                                        }
                                        getFile={file => {
                                            setFormState({...formState, license_back_upload: file});
                                        }}
                                        onRemove={() => setFormState({...formState, license_back_upload: {server: ""}})}
                                        crud={props.crud}
                                    />
                                </div>
                            </div>
                        </> : null
                    }
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className="btn btn-primary btn-width mx-auto d-block" onClick={submitHandler}>Save</button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default TeamForm