/**
 * App Header
 */
 import React, { useContext, useEffect, useState } from 'react';
 import { useLocation } from 'react-router-dom';
 import {headerlogo, Avatar_02,Avatar_03,Avatar_05,
   Avatar_06,Avatar_08,Avatar_09,Avatar_13,Avatar_17,Avatar_21} from '../utils/imagepath'
import useAuth from '../hooks/useAuth';
import Select from 'react-select';
import { CommonContext } from '../context/CommonContext';
import { useStoreList } from '../hooks/StoreHooks';
import { USER_ROLE } from '../utils/Constants';
 
const Header = (props) => {
    const location = useLocation()
	const { removeLocalStorage } = useAuth()
    let pathname = location.pathname
	
	const { userInfo } = useAuth()
	const { axiosPrivate, headerSelectedStore, updateStoreHeader } = useContext(CommonContext)
	const [params, setParams] = useState({merchant_id: null, limit: 10, offset: 0, search: "", field: "store_name", sort: "ASC", paginated: false})
	const {storeData, loading, error, total} = useStoreList(axiosPrivate, params)
	useEffect(() => {
        if(userInfo.id && userInfo.account_type != USER_ROLE.ADMIN) {
            setParams({...params, merchant_id: userInfo.id})
        }else if(userInfo.account_type == USER_ROLE.ADMIN) {
            setParams({...params, merchant_id: 0})
        }
    }, [userInfo])

	useEffect(() => {
		if(storeData.length > 0)
			updateStoreHeader(storeData[0])
	}, [storeData])

	const logoutClicked = (e) => {
		e.preventDefault()
		removeLocalStorage()
		window.location.href = "/login"
	}

	const storeChange = (e) => {
		updateStoreHeader(e)
	}
	
	return (
		<div className="header" style={{right:"0px"}}>
			{/* Logo */}
			<div className="header-left">
				<a href={props.logoURL} className="logo">
					<img src={headerlogo} width={40} height={40} alt="" />
				</a>
			</div>
			{/* /Logo */}
			<a id="toggle_btn" href="" style={{display: pathname.includes('tasks') ?"none" :pathname.includes('compose') ? "none" :""}}>
				<span className="bar-icon"><span />
					<span />
					<span />
				</span>
			</a>
			{/* Header Title */}
			<div className="page-title-box">
				<h3>Bazaaro</h3>
			</div>
			{/* /Header Title */}
			<a id="mobile_btn" className="mobile_btn" href="#sidebar"><i className="fa fa-bars" /></a>
			{/* Header Menu */}
			<ul className="nav user-menu">
			{
				userInfo.account_type == USER_ROLE.MERCHANT && 
				<li className="nav-item">
					<Select 
						onChange={(e) => storeChange(e)}
						value={headerSelectedStore}
						name="store"
						placeholder="Select Store" 
						options={storeData}
						getOptionValue={(option) => `${option.id}`}
						getOptionLabel={(option) => `${option.store_name}`}
					/>
				</li>
			}
				
				{/* Notifications */}
				{/* <li className="nav-item dropdown">
					<a href="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
					<i className="fa fa-bell-o" /> <span className="badge badge-pill">3</span>
					</a>
					<div className="dropdown-menu notifications">
					<div className="topnav-dropdown-header">
						<span className="notification-title">Notifications</span>
						<a href="" className="clear-noti"> Clear All </a>
					</div>
					<div className="noti-content">
						<ul className="notification-list">
						<li className="notification-message">
							<a href="/blue/app/administrator/activities">
							<div className="media">
								<span className="avatar">
								<img alt="" src={Avatar_02} />
								</span>
								<div className="media-body">
								<p className="noti-details"><span className="noti-title">John Doe</span> added new task <span className="noti-title">Patient appointment booking</span></p>
								<p className="noti-time"><span className="notification-time">4 mins ago</span></p>
								</div>
							</div>
							</a>
						</li>
						<li className="notification-message">
							<a href="/blue/app/administrator/activities">
							<div className="media">
								<span className="avatar">
								<img alt="" src={Avatar_03} />
								</span>
								<div className="media-body">
								<p className="noti-details"><span className="noti-title">Tarah Shropshire</span> changed the task name <span className="noti-title">Appointment booking with payment gateway</span></p>
								<p className="noti-time"><span className="notification-time">6 mins ago</span></p>
								</div>
							</div>
							</a>
						</li>
						<li className="notification-message">
							<a href="/blue/app/administrator/activities">
							<div className="media">
								<span className="avatar">
								<img alt="" src={Avatar_06} />
								</span>
								<div className="media-body">
								<p className="noti-details"><span className="noti-title">Misty Tison</span> added <span className="noti-title">Domenic Houston</span> and <span className="noti-title">Claire Mapes</span> to project <span className="noti-title">Doctor available module</span></p>
								<p className="noti-time"><span className="notification-time">8 mins ago</span></p>
								</div>
							</div>
							</a>
						</li>
						<li className="notification-message">
							<a href="/blue/app/administrator/activities">
							<div className="media">
								<span className="avatar">
								<img alt="" src={Avatar_17} />
								</span>
								<div className="media-body">
								<p className="noti-details"><span className="noti-title">Rolland Webber</span> completed task <span className="noti-title">Patient and Doctor video conferencing</span></p>
								<p className="noti-time"><span className="notification-time">12 mins ago</span></p>
								</div>
							</div>
							</a>
						</li>
						<li className="notification-message">
							<a href="/blue/app/administrator/activities">
							<div className="media">
								<span className="avatar">
								<img alt="" src={Avatar_13} />
								</span>
								<div className="media-body">
								<p className="noti-details"><span className="noti-title">Bernardo Galaviz</span> added new task <span className="noti-title">Private chat module</span></p>
								<p className="noti-time"><span className="notification-time">2 days ago</span></p>
								</div>
							</div>
							</a>
						</li>
						</ul>
					</div>
					<div className="topnav-dropdown-footer">
						<a href="/blue/app/administrator/activities">View all Notifications</a>
					</div>
					</div>
				</li> */}
				{/* /Notifications */}
				{/* Message Notifications */}
				{/* <li className="nav-item dropdown">
					<a href="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
					<i className="fa fa-comment-o" /> <span className="badge badge-pill">8</span>
					</a>
					<div className="dropdown-menu notifications">
					<div className="topnav-dropdown-header">
						<span className="notification-title">Messages</span>
						<a href="" className="clear-noti"> Clear All </a>
					</div>
					<div className="noti-content">
						<ul className="notification-list">
						<li className="notification-message">
							<a href="/blue/conversation/chat">
							<div className="list-item">
								<div className="list-left">
								<span className="avatar">
									<img alt="" src={Avatar_09} />
								</span>
								</div>
								<div className="list-body">
								<span className="message-author">Richard Miles </span>
								<span className="message-time">12:28 AM</span>
								<div className="clearfix" />
								<span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
								</div>
							</div>
							</a>
						</li>
						<li className="notification-message">
							<a href="/blue/conversation/chat">
							<div className="list-item">
								<div className="list-left">
								<span className="avatar">
									<img alt="" src={Avatar_02} />
								</span>
								</div>
								<div className="list-body">
								<span className="message-author">John Doe</span>
								<span className="message-time">6 Mar</span>
								<div className="clearfix" />
								<span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
								</div>
							</div>
							</a>
						</li>
						<li className="notification-message">
							<a href="/blue/conversation/chat">
							<div className="list-item">
								<div className="list-left">
								<span className="avatar">
									<img alt="" src={Avatar_03} />
								</span>
								</div>
								<div className="list-body">
								<span className="message-author"> Tarah Shropshire </span>
								<span className="message-time">5 Mar</span>
								<div className="clearfix" />
								<span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
								</div>
							</div>
							</a>
						</li>
						<li className="notification-message">
							<a href="/blue/conversation/chat">
							<div className="list-item">
								<div className="list-left">
								<span className="avatar">
									<img alt="" src={Avatar_05} />
								</span>
								</div>
								<div className="list-body">
								<span className="message-author">Mike Litorus</span>
								<span className="message-time">3 Mar</span>
								<div className="clearfix" />
								<span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
								</div>
							</div>
							</a>
						</li>
						<li className="notification-message">
							<a href="/blue/conversation/chat">
							<div className="list-item">
								<div className="list-left">
								<span className="avatar">
									<img alt="" src={Avatar_08} />
								</span>
								</div>
								<div className="list-body">
								<span className="message-author"> Catherine Manseau </span>
								<span className="message-time">27 Feb</span>
								<div className="clearfix" />
								<span className="message-content">Lorem ipsum dolor sit amet, consectetur adipiscing</span>
								</div>
							</div>
							</a>
						</li>
						</ul>
					</div>
					<div className="topnav-dropdown-footer">
						<a href="/blue/conversation/chat">View all Messages</a>
					</div>
					</div>
				</li> */}
				{/* /Message Notifications */}
				<li className="nav-item dropdown has-arrow main-drop">
					<a href="#" className="dropdown-toggle nav-link" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
						<span className="user-img"><img src={Avatar_21} alt="" />
							<span className="status online" /></span>
						<span>{userInfo.name}</span>
					</a>
					<div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
						{/* <a className="dropdown-item" href="/blue/app/profile/employee-profile">My Profile</a>
						<a className="dropdown-item" href="/blue/settings/companysetting">Settings</a> */}
						<a className="dropdown-item" href="#" onClick={(e) => logoutClicked(e)}>Logout</a>
					</div>
				</li>
			</ul>
			{/* /Header Menu */}
			{/* Mobile Menu */}
			<div className="dropdown mobile-user-menu">
				<a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><i className="fa fa-ellipsis-v" /></a>
				<div className="dropdown-menu dropdown-menu-right">
					<a className="dropdown-item" href="/blue/app/profile/employee-profile">My Profile</a>
					<a className="dropdown-item" href="/blue/settings/companysetting">Settings</a>
					<a className="dropdown-item" href="#" onClick={(e) => logoutClicked(e)}>Logout</a>
				</div>
			</div>
			{/* /Mobile Menu */}
		</div>
		
	);
 }
 
 export default Header;