/**
 * App Header
 */
import React, { Component } from 'react';
import { Link, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { AREA_PREFIX, ALL_ORDERS, BANNER_PREFIX, CATEGORY_PREFIX, MERCHANT_PREFIX, PRODUCT_PREFIX, ROUTE_PREFIX, STORE_PREFIX, ORDER_PREFIX, FLEET_PREFIX, TEAM_PREFIX, F_D_CHANGE,COUPON_PREFIX,REVIEW_PREFIX,GIFT_PREFIX,PUSHNOTICE_PREFIX } from '../../utils/Constants';
 
 const AdminSidebar = (props) => {
    const location = useLocation()
    let pathname = location.pathname
    let { auth } = useAuth()
    return (
        <div className="sidebar" id="sidebar">
            <div className="sidebar-inner slimscroll">
                <div id="sidebar-menu" className="sidebar-menu">
                {
                    auth.permissions.length === 0 ? <p>Loading Menu</p> : 
                    <ul>
                    {
                        auth.permissions.includes("Dashboard") && 
                        <li className={pathname.includes('dashboard') ?"active" : ""}> 
                            <Link to={`/dashboard`}><i className="la la-home" /> <span>Dashboard</span></Link>
                        </li>
                    }
                    {
                        auth.permissions.includes("TeamListing") &&
                        <li className={pathname.includes(`app-setting`) ?"active" : ""}> 
                            <Link to={`/app-setting`}><i className="fa fa-gear" /> <span>HomePage Settings</span></Link>
                        </li>
                    }
					{
                        auth.permissions.includes("TeamListing") &&
                        <li className={pathname.includes(`${TEAM_PREFIX}`) ?"active" : ""}> 
                            <Link to={`${TEAM_PREFIX}`}><i className="fa fa-user" /> <span>Staff</span></Link>
                        </li>
                    }
					{
                        auth.permissions.includes("TeamListing") &&
                        <li className={pathname.includes(`${TEAM_PREFIX}`) ?"active" : ""}> 
                            <Link to={`attendance`}><i className="fa fa-user" /> <span>Staff Attendance </span></Link>
                        </li>
                    }
                    {
                        auth.permissions.includes("ListMerchant") &&
                        <li className={pathname.includes(`${MERCHANT_PREFIX}`) ?"active" : ""}> 
                            <Link to={`${MERCHANT_PREFIX}`}><i className="fa fa-id-card" /> <span>Merchants</span></Link>
                        </li>
                    }
                    {
                        auth.permissions.includes("ListingBanner") &&
                        <li className={pathname.includes(`${BANNER_PREFIX}`) ?"active" : ""}> 
                            <Link to={`${BANNER_PREFIX}`}><i className="fa fa-picture-o" /> <span>Banners</span></Link>
                        </li>
                    }
                    {
                        auth.permissions.includes("ListStore") &&
                        <li className={pathname.includes(`${STORE_PREFIX}`) ?"active" : ""}> 
                            <Link to={`${STORE_PREFIX}`}><i className="fa fa-id-card" /> <span>Stores</span></Link>
                        </li>
                    }
                    {
                        auth.permissions.includes("ListOrder") && 
                        <li className={pathname.includes(`${ORDER_PREFIX}`) ?"active" : ""}> 
                            <Link to={`${ORDER_PREFIX}`}><i className="fa fa-id-card" /> <span>Orders</span></Link>
                        </li>
                    }
					{
                        auth.permissions.includes("ListOrder") && 
                        <li className={pathname.includes(`${ORDER_PREFIX}/order-products`) ?"active" : ""}> 
                            <Link to={`${ORDER_PREFIX}/order-products`}><i className="fa fa-id-card" /> <span>Orders Products Stock</span></Link>
                        </li>
                    }
                    {
                        auth.permissions.includes("ListCategory") &&
                        <li className={pathname.includes(`${CATEGORY_PREFIX}`) ?"active" : ""}> 
                            <Link to={`${CATEGORY_PREFIX}`}><i className="fa fa-th-list" /> <span>Categories</span></Link>
                        </li>
                    }
					{
                        auth.permissions.includes("CouponListing") &&
                        <li className={pathname.includes(`${COUPON_PREFIX}`) ?"active" : ""}> 
                            <Link to={`${COUPON_PREFIX}`}><i className="fa fa fa-download" /> <span>Coupons</span></Link>
                        </li>
                    }
					{
                        auth.permissions.includes("CouponListing") &&
                        <li className={pathname.includes(`${GIFT_PREFIX}`) ?"active" : ""}> 
                            <Link to={`${GIFT_PREFIX}`}><i className="fa fa fa-download" /> <span>Gift Cards</span></Link>
                        </li>
                    }
					{
                        auth.permissions.includes("CouponListing") &&
                        <li className={pathname.includes(`${REVIEW_PREFIX}`) ?"active" : ""}> 
                            <Link to={`${REVIEW_PREFIX}`}><i className="fa fa fa-download" /> <span>Reviews</span></Link>
                        </li>
                    }					
                    {
                        (auth.permissions.includes("CityListing") || auth.permissions.includes("RouteListing") || auth.permissions.includes("FleetListing") || auth.permissions.includes("ChangeDeliveryFleetDriver")) &&
                        <>
                            <li className="menu-title"><span>Route</span></li>
                            {
                                auth.permissions.includes("CityListing") && 
                                <li className={pathname.includes(`${AREA_PREFIX}`) ?"active" : ""}> 
                                    <Link to={`${AREA_PREFIX}`}><i className="fa fa-map-marker" /> <span>City</span></Link>
                                </li>
                            }
                            {
                                auth.permissions.includes("RouteListing") && 
                                <li className={pathname.includes(`${ROUTE_PREFIX}`) ?"active" : ""}> 
                                    <Link to={`${ROUTE_PREFIX}`}><i className="fa fa-map-o" /> <span>Routes</span></Link>
                                </li>
                            }
                            {
                                auth.permissions.includes("FleetListing") && 
                                <li className={pathname.includes(`${FLEET_PREFIX}`) ?"active" : ""}> 
                                    <Link to={`${FLEET_PREFIX}`}><i className="fa fa-bus" /> <span>Fleets</span></Link>
                                </li>
                            }
                            {
                                auth.permissions.includes("AuditLogListing") && 
                                <li className={pathname.includes(`${F_D_CHANGE}`) ?"active" : ""}> 
                                    <Link to={`${F_D_CHANGE}`}><i className="fa fa-bus" /> <span>Fleets/Driver Change Log</span></Link>
                                </li>
                            }
                        </>
                    }
					{
                       auth.permissions.includes("ListCustomer") || auth.permissions.includes("ListCustomerSMS") ?
                        <>
                            <li className="menu-title"><span>Reports & analysis</span></li>
                            {
                                auth.permissions.includes("ListCustomer") ?
                                <li className={pathname.includes(`weekly-reports`) ?"active" : ""}> 
                                    <Link to={`weekly-reports`}><i className="fa fa-product-hunt" /> <span>Weekly </span></Link>
                                </li> : null
                            }
                            {
                                auth.permissions.includes("ListCustomer") ?
                                <li className={pathname.includes(`monthly-reports`) ?"active" : ""}> 
                                    <Link to={`monthly-reports`}><i className="fa fa-product-hunt" /> <span>Monthly </span></Link>
                                </li> : null
                            }    
                            {
                                auth.permissions.includes("ListCustomer") ?
                                <li className={pathname.includes(`customers-reports`) ?"active" : ""}> 
                                    <Link to={`customers-reports`}><i className="fa fa-product-hunt" /> <span>Customers </span></Link>
                                </li> : null
                            }
                            {
                                auth.permissions.includes("ListCustomer") ?
                                <li className={pathname.includes(`products-reports`) ?"active" : ""}> 
                                    <Link to={`products-reports`}><i className="fa fa-product-hunt" /> <span>Product Sales </span></Link>
                                </li> : null
                            }
                            {
                                auth.permissions.includes("ListCustomer") ?
                                <li className={pathname.includes(`products-in-cart-reports`) ?"active" : ""}> 
                                    <Link to={`products-in-cart-reports`}><i className="fa fa-product-hunt" /> <span>Products In Cart</span></Link>
                                </li> : null
                            }							
							
                        </> : null
                    }
                    {
                        auth.permissions.includes("UploadProductCSV") || auth.permissions.includes("ListProduct") ?
                        <>
                            <li className="menu-title"><span>Product</span></li>
                            {
                                auth.permissions.includes("ListProduct") ?
                                <li className={pathname.includes(`${PRODUCT_PREFIX}`) ?"active" : ""}> 
                                    <Link to={`${PRODUCT_PREFIX}`}><i className="fa fa-product-hunt" /> <span>Products</span></Link>
                                </li> : null
                            }
                            {
                                auth.permissions.includes("UploadProductCSV") ?
                                <li className={pathname.includes(`${PRODUCT_PREFIX}/csv`) ?"active" : ""}> 
                                    <Link to={`${PRODUCT_PREFIX}/csv`}><i className="fa fa-upload" /> <span>Upload Product CSV</span></Link>
                                </li> : null
                            }
                            
                        </> : null
                    }
					{
                       auth.permissions.includes("ListCustomer") || auth.permissions.includes("ListCustomerSMS") ?
                        <>
                            <li className="menu-title"><span>Customer Management</span></li>
							{
                                auth.permissions.includes("ListCustomer") ?
                                <li className={pathname.includes(`support-tickets`) ?"active" : ""}> 
                                    <Link to={`support-tickets`}><i className="fa fa-product-hunt" /> <span>Support Tickets</span></Link>
                                </li> : null
                            }
                            {
                                auth.permissions.includes("ListCustomer") ?
                                <li className={pathname.includes(`customers`) ?"active" : ""}> 
                                    <Link to={`customers`}><i className="fa fa-product-hunt" /> <span>Customers</span></Link>
                                </li> : null
                            }
                            {
                                auth.permissions.includes("ListCustomerSMS") ?
                                <li className={pathname.includes(`${PUSHNOTICE_PREFIX}`) ?"active" : ""}> 
                                    <Link to={`${PUSHNOTICE_PREFIX}`}><i className="fa fa-upload" /> <span>Push Notification</span></Link>
                                </li> : null
                            }                            
                        </> : null
                    }
                    </ul>
                }
                </div>
            </div>
        </div>
        
    );
 }
 
 export default AdminSidebar;
 