import { Table } from 'antd';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import { Helmet } from 'react-helmet-async';
import { Link,useParams } from 'react-router-dom';
import moment from 'moment';
import { itemRender } from '../Components/paginationfunction';
import { CommonContext } from '../context/CommonContext';
import { useOrderCommentsListing } from '../hooks/OrderHooks';
import { orderService } from '../Services/OrderService';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { PRODUCT_PREFIX, TOAST_MSG_TYPE, getConstantData,CUSTOMER_PREFIX,ORDER_PREFIX} from '../utils/Constants';
import { productService } from '../Services/ProductService';
const initialState = { comment_text: ""}
const CardDetails = (props) => {
	const navigate = useNavigate()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo, dashboardPrefix } = useAuth()
    let { orderID } = useParams()
	const [formState, setFormState] = useState({...initialState})
	const [sendEmail,setSendEmail]= useState(false)
	const [customerFixedNote,setCustomerFixedNote]= useState(false)
	const [params, setParams] = useState({paginated: true, limit: 10, offset: 0, search: "", field: "id", sort: "DESC", counter: 0,order_id: orderID})
	console.log("order_id",orderID)
    const {orderLog, loading, error, total} = useOrderCommentsListing(axiosPrivate, params)
	console.log(orderLog)
    const render = useMemo(() => {
        const columns = [		    
            {
                title: 'id',
                dataIndex: 'id'
            },{
                title: 'Comment',
                dataIndex: 'reward_points',
				render: (text,row) => {
                    return row.event_new_data.comment_text
                }
            },{
                title: 'Created At',
                dataIndex: 'createdAt',
				render: (text,row) => {
                    return moment.unix(row.createdAt/1000).format("YYYY-MM-DD: HH:mm:ss")
                }
            },{
                title: 'Delete',
                dataIndex: 'order_id',
				render: (text,row) => {
                    return <i className="fa fa-trash red-icon" onClick={() => deleteProduct(row.id)}></i>
                }
            },
        ]
        return columns
    }, [auth.permissions])

    const onChangeField = (e) => {  
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }
    const deleteProduct = (id) => {
		var paramData= {
			id: id
		}
        const apiPromise = orderService.deleteOrderComment(axiosPrivate, paramData)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.deleteOrderComment) {
                showToast(props.crud === "create" ? "Comment Delete Sucessfully!" : "Comment Delete Sucessfully", TOAST_MSG_TYPE.SUCCESS)
				window.location.reload();
            }
        })
    }
    const submitHandler = (e) => {
        e.preventDefault()
        if(!formState.comment_text) {
            showToast("Please enter comment", TOAST_MSG_TYPE.ERROR)
            return
        }
        let paramData = {
			send_notification: sendEmail,
			customer_fixed_note: customerFixedNote,
            comment_text: formState.comment_text,
			order_id: orderID,
        }
		console.log(paramData)
        const apiPromise = orderService.addOrderComment(axiosPrivate, paramData)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.addOrderComment) {
                showToast(props.crud === "create" ? "Comment Updated Sucessfully!" : "Comment Updated Sucessfully", TOAST_MSG_TYPE.SUCCESS)
				if(customerFixedNote){
					navigate(`${ORDER_PREFIX}/${orderID}`)
				}
				window.location.reload();
            }
        })
    }
	const checkboxChangedForote = (e) => {
		var newValue = !customerFixedNote
		setCustomerFixedNote(newValue)
    }
	const checkboxChanged = (e) => {
		var newValue = !sendEmail
		setSendEmail(newValue)
    }
    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Products</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Order Admin Comments</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">Comments</li>
								<li className="breadcrumb-item active">#{orderID}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-3'>
                               
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= {
									{
									total : total,
									pageSize: 10, 
									showSizeChanger : false,
									itemRender : itemRender 
									} 
								}
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={loading}               
                                bordered
                                dataSource={orderLog}
                            />
                        </div>
                    </div>
					<div className="card-body">
					<div className='col-md-11'>
						<div className='form-group'>
						<label>Comment*</label>
						<textarea className='form-control' rows={4} value={formState.comment_text} name='comment_text' onChange={onChangeField}></textarea>
						</div>
						<div className="col-md-12">
						<input type="checkbox"  style={{marginRight: "10px"}} checked={sendEmail} onChange={checkboxChanged}/>
						<label htmlFor="notification_flag"><b>Send Notification To Customer ?</b></label>
						</div>
						<div className="col-md-12">
						<input type="checkbox"  style={{marginRight: "10px"}} checked={customerFixedNote} onChange={checkboxChangedForote}/>
						<label htmlFor="notification_flag"><b>Customer Fixed Note For Next Orders ?</b></label>
						</div>
						<button className='btn btn-primary mt-40 float-right text-white btn-width' onClick={submitHandler}>Submit</button>
                    </div>
                         
					</div>
				 </div>
            </div>
        </div>
    
    );
}
export default CardDetails