import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import ReactSelect from 'react-select';
import { Button, Modal, Select, Table, Switch, Image } from 'antd';
import 'antd/dist/antd.css';
import {itemRender} from "../Components/paginationfunction"
import "../assets/css/antdstyle.css"
import { CommonContext } from '../context/CommonContext';
import useAuth from '../hooks/useAuth';
import { useOrderProductsList } from '../hooks/OrderHooks';
import { useMenusList } from '../hooks/ProductHooks';
import moment from 'moment';
import { API_BASE_URL, ORDER_PREFIX, TOAST_MSG_TYPE,CATEGORY_IMG } from '../utils/Constants';
import { orderService } from '../Services/OrderService';
import { Collapse } from 'antd';
const { Panel } = Collapse;


const OrderList = () => {
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo, dashboardPrefix } = useAuth()
	const [Options, setOptions] = useState([]);
    const [menuparams, setMenuParams] = useState({store: [] })
	const {menuData, loading2} = useMenusList(axiosPrivate, menuparams)
    const [params, setParams] = useState({productCategory:0,paginated: true, limit: 20, offset: 0, searchTerm: "", searchDate: localStorage.getItem("searchDateORDER") ? localStorage.getItem("searchDateORDER") :  moment().toISOString().slice(0, 10), sortBy: "id", sortOrder: "DESC"})
    const {orderData, loading: orderLoading, total: orderTotal} = useOrderProductsList(axiosPrivate, params)
	
	const rowClassName = (orderData) => {
	return orderData.pflag === 1
	? "selected-row-red" 
	: "";
	};
    const render = useMemo(() => {
        const columns = [
            {
                title: 'Img',
                render: (text, item) => {
					return <Image height={30} src={`${CATEGORY_IMG}/${item.product_image}`}/>
				}
            },		
			{
                title: 'Product Title',
                dataIndex: 'product_title',
				render: (text, item) => {
                    return `${item.product_title}(${item.product_brand})`
                }
            },
			{
                title: 'Qty',
                dataIndex: 'total_qty'
            },
			{
                title: 'Product Variant',
                dataIndex: 'product_variant'
            },
			{
				title: 'Flag',
				render: (text, item) => {
				return <Switch checked={item.pflag} onChange={(checked) => handleSwitchChange(checked, item.product_id)}  />
				}
			},			
            {
                title: 'ProductId',
                dataIndex: 'product_id',
            }, 
			{
                title: 'Total Price',
                dataIndex: 'total_price'
            },
            {
                title: 'Supplier Price',
                dataIndex: 'supplier_price'
            }
        ]
        return columns
    }, [auth.permissions, auth.constants])
    const handleSwitchChange = (checked, index) => {
		let formState={ product_id: Number(index), delivery_date: localStorage.getItem("searchDateORDER"),pflag:Number(checked)}
		console.log(formState)
		const apiPromise = orderService.setOrderProductFlag(axiosPrivate, formState)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.setOrderProductFlag) {
                window.location.reload();
            }
        })		
	};
    const searchChanged = (e) => {		
        const value = e.target.value
        setParams({...params, offset: 0, searchDate: value})		
		localStorage.setItem("searchDateORDER",value);
        console.log(value)
    }
    const onChangeMenu = (val) => {
		setParams({...params, productCategory: parseInt(val.value)})
	}
    const handleTableChange = (pagination, filters, sorter, extra) => {
        if("order" in sorter) {
            let offset = pagination.pageSize * (pagination.current - 1)
            setParams({...params, offset: offset, field: sorter.field, sort: sorter.order === "ascend" ? "ASC" : "DESC"})
            return
        }
        let offset = pagination.pageSize * (pagination.current - 1)
        setParams({...params, offset: offset})
    }
    const filterChange = (e, name, type) => {
        let newVal = e.target.value
        switch(type) {
            case "num":
                newVal = Number(newVal)
                break
            default:
                break
        }
        setParams({
            ...params,
            offset: 0,
            [name]: newVal
        })
		localStorage.setItem(name, newVal)
    }
    const fleetChanged = (e) => {
        if(e == 0) {
            setParams({...params, searchDeliveryFleetId: null})    
        }else {
			localStorage.setItem("searchDeliveryFleetId", e)
            setParams({...params, searchDeliveryFleetId: e})
        }
    }
	const clearAllFilters  = (e) => {
		localStorage.setItem("searchDateORDER","");		
		window.location.reload();		
	}
    const downloadDispatchList = (e) => {
        e.preventDefault()
        if(!params.searchDate) {
            showToast("Please Select Date From Filter", TOAST_MSG_TYPE.ERROR)
            return
        }
        const apiPromise = orderService.getDispatchList(axiosPrivate, params.searchDate)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res && res.data.ordersEXcelSheet.path) {
                const url = `${API_BASE_URL}${res.data.ordersEXcelSheet.path.replace("./", "")}`
                createLink(url)
            }
        })
    }
	const downloadOrderPorudtcsData = (e) => {
        e.preventDefault()
        if(!params.searchDate) {
            showToast("Please Select Date From Filter", TOAST_MSG_TYPE.ERROR)
            return
        }
        const apiPromise = orderService.getOrderProductsList(axiosPrivate, params.searchDate)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res && res.data.excelOrdersProductsByDeliveryDate.path) {
                const url = `${API_BASE_URL}${res.data.excelOrdersProductsByDeliveryDate.path.replace("./", "")}`
                createLink(url)
            }
        })
    }
	useEffect(()=>{
        const categoryList = menuData;
        const options = categoryList?.map(category => ({
            label: category.main_cat_title,
            options: category.subcats.map(subcat => ({
              value: parseInt(subcat.menu_item_id), 
              label: subcat.child_cat_title
            }))
        }));
        setOptions(options);
    },[menuData])
    const createLink = (url) => {
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Order List</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Order List</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active">Order List </li>
								<li className="breadcrumb-item active">Total Order: {orderTotal} </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <Collapse>
                            <Panel header="Filters" key="1">
                                <div className='row filter-row'>
                                    <div className='col-md-3'>
                                        <label className='mobile-lable-spacing'>Search:</label>
                                        <input type="text" placeholder='Search' value={params.searchTerm} className='form-control table-search' onChange={(e) => filterChange(e, "searchTerm", "string")}/>
                                    </div>
                                    
                                    
                                    <div className='col-md-3'>
                                        <label className='mobile-lable-spacing'>Delivery Date/Time:</label>
                                        <input type="date" value={params.searchDate} placeholder='Delivery Date/Time' className='float-right form-control table-search' onChange={(e) => searchChanged(e)}/>
                                    </div>
									
                                    <div className='col-md-3'>
									<div className='form-group'>
											<label>Select Category/Menu</label>
											<ReactSelect
												options={Options}
												onChange={onChangeMenu}
												isLoading={false}
												isDisabled={false}
												loadingMessage={"Loading Category"}
											/>
									</div>
									</div>
									<div className='col-md-2'>
									<Link to={"#"} onClick={clearAllFilters} className="btn btn-info" style={{marginRight: "10px",marginTop: "10px"}}>Clear Filters</Link>
									</div>
									<div className='col-md-4'>
									<Link to={"#"} onClick={downloadOrderPorudtcsData} className="btn btn-info" style={{marginRight: "10px",marginTop: "10px"}}>Download products List by Date </Link>
									</div>
									
                                </div>
                            </Panel>
                        </Collapse>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= { {total : orderTotal,pageSize: 20, 
                                    showSizeChanger : false,itemRender : itemRender } }
                                columns={render}
                                loading={orderLoading}               
                                bordered
                                dataSource={orderData}
                                rowKey={record => record.order_id}
                                onChange={handleTableChange}
								rowClassName={rowClassName}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default OrderList;