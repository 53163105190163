import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link,useNavigate } from 'react-router-dom';
import { FLEET_PREFIX, TOAST_MSG_TYPE, getConstantData, API_BASE_URL,YEAR_LIST } from '../utils/Constants';
import { Table,Select } from 'antd';
import 'antd/dist/antd.css';
import {itemRender} from "../Components/paginationfunction"
import "../assets/css/antdstyle.css"
import { CommonContext } from '../context/CommonContext';
import useAuth from '../hooks/useAuth';
import { useOrderReports } from '../hooks/FleetHooks';
import { fleetService } from '../Services/FleetService';
const monthsOrder = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const FleetList = () => {
	
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const { auth, userInfo, dashboardPrefix } = useAuth()
    const [params, setParams] = useState({report_name: "CUSTOMERS_REPORT",paginated: false, limit: 20, offset: 0, search: "", year_filter:2025})
    const {fleetData, loading, error, total} = useOrderReports(axiosPrivate, params)
	const [cartProducts, setCartProducts] = useState([])
	
	useEffect(() => {
		if(fleetData){
            var newdata=fleetData.sort((a, b) => monthsOrder.indexOf(a.month_name) - monthsOrder.indexOf(b.month_name));
			setCartProducts(newdata)
        }
    }, [loading])
    const render = useMemo(() => {
        const columns = [
            {
                title: 'Month',
                dataIndex: 'month_name'
            },
            {
                title: 'Total Customers',
                dataIndex: 'total_customers'
            }
        ]
        return columns
    }, [auth.permissions])
    const onBusinessYear = (e) => {
		setParams({...params,year_filter:e})
	}
    const searchChanged = (e) => {
        const value = e.target.value
        setParams({...params, offset: 0, search: value})
    }

    const handleTableChange = (pagination, filters, sorter, extra) => {
        if("order" in sorter) {
            let offset = pagination.pageSize * (pagination.current - 1)
            setParams({...params, offset: offset, field: sorter.field, sort: sorter.order === "ascend" ? "ASC" : "DESC"})
            return
        }
        let offset = pagination.pageSize * (pagination.current - 1)
        setParams({...params, offset: offset})
    }
	const createLink = (url) => {
        const link = document.createElement('a');
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }
	const downloadExcel = (e) => {
        let params={
			is_excel: true,
			report_name: "CUSTOMERS_REPORT",
			paginated: false, 
			limit: 20, 
			offset: 0, 
			search: ""
		}
        const apiPromise = fleetService.orderReportsData(axiosPrivate, params)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res && res.data.orderReportsData.path) {
                const url = `${API_BASE_URL}${res.data.orderReportsData.path.replace("./", "")}`
                createLink(url)
            }
        })
    }

    return (
        <div className="page-wrapper">
            <Helmet>
                <title>Manage Customers Monthly Report</title>
            </Helmet>
            <div className="content container-fluid">
                <div className="page-header">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3 className="page-title">Manage Customers Monthly Report</h3>
                            <ul className="breadcrumb">
                                <li className="breadcrumb-item"><Link to={`/dashboard`}>Dashboard</Link></li>
                                <li className="breadcrumb-item active"> Customers Monthly Report</li>
                            </ul>
                        </div>
                        {
                            auth.permissions.includes("AddFleet") ?
                            <div className="col-sm-6">
                                <Link to={"#"} onClick={downloadExcel} className="btn btn-primary btn-width float-right d-block">Download Excel</Link>
                            </div> : null
                        }
                    </div>
                </div>
                <div className="card mb-0">
                    <div className="card-header">
                        <div className='row filter-row'>
                            <div className='col-md-3'>
                                <input type="text" placeholder='Search' className='float-right form-control table-search' onChange={(e) => searchChanged(e)}/>
                            </div>
							<div className="col-md-3"> 
									<Select 
										className='select col-md-12'
										value={params.year_filter}
										options={YEAR_LIST}
										onChange={onBusinessYear}
									/>
					        </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <Table
                                pagination= { {total : total,pageSize: 1000, 
                                itemRender : itemRender } }
                                style = {{overflowX : 'auto'}}
                                columns={render}
                                loading={loading}               
                                bordered
                                dataSource={cartProducts}
                                rowKey={record => record.id}
                                onChange={handleTableChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
    );
}

export default FleetList;