class RouteService {
    createUpdateCoupon(axios, params, couponImage) {
		console.log(params);
        let query = `
                mutation($id: ID,$coupon_max_amount: Float,$is_rewardpoints:Int, $coupon_image: Upload,$coupon_title: String,$coupon_code: String!,$coupon_amount: Float,$is_percent: Boolean,$product_variant_id: Int,$new_user: Boolean,$min_purchase_amout: Float,$total_number_coupons: Int,$start_date: String!,$end_date: String!,$coupon_type: String,$coupon_desc: String,$category_id: Int,$per_user: Int,$coupon_merchants:[Int]) {
                    createUpdateCoupon(
					    id: $id
						coupon_title: $coupon_title
                        coupon_code: $coupon_code
                        coupon_amount: $coupon_amount
                        is_percent: $is_percent
                        product_variant_id: $product_variant_id
                        new_user: $new_user
						min_purchase_amout: $min_purchase_amout
						total_number_coupons: $total_number_coupons
						start_date: $start_date
						end_date: $end_date
						coupon_type: $coupon_type
						coupon_desc: $coupon_desc
						category_id: $category_id
						per_user: $per_user
						coupon_merchants: $coupon_merchants
						coupon_image: $coupon_image
						coupon_max_amount: $coupon_max_amount
						is_rewardpoints: $is_rewardpoints
                    )
                }
            `
			
		const operations = 
		{
			query, 
			variables: {
				id: params.id,
				coupon_title: params.coupon_title,
				coupon_code: params.coupon_code,
				coupon_amount: parseFloat(params.coupon_amount),
				is_percent: params.is_percent,
				product_variant_id: parseInt(params.product_id),
				new_user: params.new_user,
				min_purchase_amout: params.min_purchase_amout,
				total_number_coupons: params.total_number_coupons,
				start_date: params.start_date+"",
				end_date: params.end_date+"",
				coupon_type: params.coupon_type,
				coupon_desc: params.coupon_desc,
				category_id: parseInt(params.category_id),
				per_user: params.per_user,
				coupon_merchants: params.coupon_merchants,
				coupon_image: null,
				coupon_max_amount: params.coupon_max_amount,
				is_rewardpoints: params.is_rewardpoints
			}
		}
        if(typeof couponImage === "object") {
            let formData = new FormData()
            formData.append("operations", JSON.stringify(operations))
            const map = {
                "0": ["variables.coupon_image"]
            }
            formData.append("map", JSON.stringify(map))
            formData.append("0", couponImage)
            return axios.post('', formData).then(res => {
                return res
            }).catch(err => {
                return Promise.reject(err)
            })
        }else {
            if(typeof couponImage === "string" && couponImage !== "") {
                operations.variables.coupon_image = couponImage
            }
            return axios.post('', JSON.stringify(operations)).then(res => {
                return res
            }).catch(err => {
                return Promise.reject(err)
            })
        }
    }
	getGiftCouponsExcel(axios, params) {
		console.log(params);
        let body = {
            query: `
                query {
                    downloadAllCouponForAGrift(
					id: ${params.id}
					title: "${params.title}"
					)
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	excelAllCustomerSearchs(axios, params) {
        let body = {
            query: `
                query {
                    excelAllCustomerSearchs
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	getCustomersExcel(axios, params) {
        let body = {
            query: `
                query {
                    excelAllCustomersAdmin
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	getOrderedCustomersExcel(axios, params) {
        let body = {
            query: `
                query {
                    downloadOrderedCustomers
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	downloadSingleOrderedCustomersExcel(axios, params) {
        let body = {
            query: `
                query {
                    downloadSingleOrderedCustomers
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	ownloadCartCustomersExcel(axios, params) {
        let body = {
            query: `
                query {
                    downloadCartCustomers
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	getNoOrderCustomersExcel(axios, params) {
        let body = {
            query: `
                query {
                    downloadNoOrderCustomers
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	getHighDemandExcel(axios, params) {
        let body = {
            query: `
                query {
                    excelHighinDemandProducts
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	getHighPricnignExcel(axios, params) {
        let body = {
            query: `
                query {
                    excelHighinEarningProducts
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	GenerateGiftCard(axios, params) {
		console.log("params");
		console.log(params);
		console.log(`"${params.giftcard_startdate}"`);
        let body = {
            query: `
                mutation {
                    GenerateGiftCard(
                        giftcard_name: "${params.giftcard_name}"
                        giftcard_type: "${params.giftcard_type}"
                        giftcard_couponcode: "${params.giftcard_couponcode}"
                        giftcard_totalnumbers: ${params.giftcard_totalnumbers}
                        giftcard_order_min_amount: ${params.giftcard_order_min_amount}
						giftcard_amount: ${params.giftcard_amount}
						giftcard_startdate: "${params.giftcard_startdate}"
						giftcard_enddate: "${params.giftcard_enddate}"
						giftcard_desc: "${params.giftcard_desc}"
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	addUpdateArea(axios, params) {
        let query = `
                mutation($city_id: Int,$area_name:String!,$shipping_charges:Float,$shipping_charges_2:Float,$min_order_amount:Float,$min_order_amount_2:Float,$free_shipping_amount:Float $free_delivary_days: [String],$free_shipping_day_amount:Float) {
                    createUpdateCity(
                        city_id: $city_id
                        area_name: $area_name
                        shipping_charges: $shipping_charges
						shipping_charges_2: $shipping_charges_2
                        min_order_amount: $min_order_amount
						min_order_amount_2: $min_order_amount_2
                        free_shipping_amount: $free_shipping_amount
						free_delivary_days: $free_delivary_days
						free_shipping_day_amount: $free_shipping_day_amount
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
					    city_id: parseInt(params.city_id),
                        area_name: params.area_name,
                        shipping_charges: params.shipping_charges,
                        min_order_amount: params.min_order_amount,
						shipping_charges_2: params.shipping_charges_2,
                        min_order_amount_2: params.min_order_amount_2,
                        free_shipping_amount: params.free_shipping_amount,
						free_delivary_days: params.free_delivary_days,
						free_shipping_day_amount: params.free_shipping_day_amount
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	addUpdateProductReviews(axios, params) {
        let query = `
                mutation($id: Int,$review_stars:Int!,$review_comment:String) {
                    addUpdateProductReviews(
                        id: $id
                        review_stars: $review_stars
                        review_comment: $review_comment
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
					    id: parseInt(params.id),
                        review_stars: parseInt(params.review_stars),
                        review_comment: params.review_comment
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    cityByID(axios, cityID) {
        let body = {
            query: `
                query {
                    cityDetailsById(
                        city_id: ${cityID}
                    ) {
                        id
                        area_name
                        city_status
                        shipping_charges
                        min_order_amount
						shipping_charges_2
                        min_order_amount_2
                        free_shipping_amount
						free_delivary_days
						free_shipping_day_amount
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	getReviewById(axios, cityID) {
        let body = {
            query: `
                query {
                    getReviewById(
                        id: ${cityID}
                    ) {
                        id
                        review_stars
		                review_comment
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	getCouponsList(axios, params) {
        let body = {
            query: `
                query {
                    couponListing(
                        paginated: ${params.paginated !== undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
                    ) {
                        list {
							id
							coupon_code
							is_percent
							coupon_amount
							coupon_type
							min_purchase_amount
							total_number_coupons
							start_date
							end_date
							is_active
							coupon_title
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	getGiftCardsList(axios, params) {
        let body = {
            query: `
                query {
                    giftCardListing(
                        paginated: ${params.paginated !== undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
                    ) {
                        list {
							id
							giftcard_name
							giftcard_type
							giftcard_couponcode
							giftcard_totalnumbers
							giftcard_order_min_amount
							giftcard_amount
							giftcard_startdate
							giftcard_enddate
							giftcard_desc
							giftcard_status
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	notificationListingCMS(axios, params) {
        let body = {
            query: `
                query {
                    notificationListingCMS(
                        paginated: ${params.paginated !== undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
                    ) {
                        list {
							id
							notification_title
							notification_type
							notification_body
							notification_status
							notification_data
							createdAt
							send_to
							opened_by
							notification_customers
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	getReviewList(axios, params) {
        let body = {
            query: `
                query {
                    productReviewsList(
                        paginated: ${params.paginated !== undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
                    ) {
                        list {
							id
							order_id
							customer_id
							variant_id
							review_stars
							review_comment
							review_status
							review_product_data
							email
							createdAt
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    getCitiesList(axios, params) {
        let body = {
            query: `
                query {
                    cityListing(
                        paginated: ${params.paginated !== undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
                    ) {
                        list {
                            id
                            area_name
                            city_status
                            min_order_amount
                            shipping_charges
							free_delivary_days
							free_shipping_day_amount
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	customerCityListing(axios, params) {
        let body = {
            query: `
                query {
                    customerCityListing
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    addUpdateRoute(axios, params) {
        let query = `
                mutation($route_id: Int, $route_name: String!, $area_data: JSON!, $days_name: JSON!, $order_limit: Int!, $route_fleets: [Int], $order_end_time: String,$notification_flag: Boolean) {
                    createUpdateRoute(
                        route_id: $route_id
                        route_name: $route_name
                        area_data: $area_data
                        days_name: $days_name
                        order_limit: $order_limit
                        route_fleets: $route_fleets
                        order_end_time: $order_end_time
						notification_flag: $notification_flag
                    )
                }
            `
        const operations = 
            {
                query, 
                variables: {
                    route_id: params.route_id,
                    route_name: params.route_name,
                    area_data: params.area_data,
                    days_name: params.days_name,
                    route_fleets: params.route_fleets,
                    order_end_time: params.order_end_time,
                    order_limit: parseInt(params.order_limit),
					notification_flag: params.notification_flag
                }
            }
        return axios.post('', JSON.stringify(operations)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    getRouteList(axios, params) {
        let body = {
            query: `
                query {
                    cityRoutesListing(
                        paginated: ${params.paginated !== undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        searchTerm: "${params.search}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
                    ) {
                        list {
                            id
                            route_name
                            area_data
                            days_name
                            order_limit
                            active
                            route_fleets
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    routeByID(axios, routeID) {
        let body = {
            query: `
                query {
                    routeDetailsById(
                        route_id: ${routeID}
                    ) {
                        id
                        route_name
                        route_fleets
                        area_data
                        days_name
                        order_limit
                        order_end_time
						notification_flag
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	couponDetailsByCouponCode(axios, coupon_code) {
			let body = {
				query: `
					query {
						couponDetailsByCouponCode(
							coupon_code: "${coupon_code}"
						) {
							id
							coupon_max_amount
							is_rewardpoints
							coupon_code
							is_percent
							coupon_amount
							coupon_type
							min_purchase_amount
							total_number_coupons
							start_date
							end_date
							is_active
							new_user
							coupon_desc
							product_id
							category_id
							per_user
							coupon_title
							coupon_merchants
							coupon_image
						}
					}
				`
			}
			return axios.post('', JSON.stringify(body)).then(res => {
				return res
			}).catch(err => {
				return Promise.reject(err)
			})
		}
    changeCityStatus(axios, params) {
        let body = {
            query: `
                mutation {
                    changeCityStatus(
                        city_id: ${params.id},
                        city_status: ${params.status}
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	changeReviewStatus(axios, params) {
        let body = {
            query: `
                mutation {
                    changeReviewStatus(
                        id: ${params.id},
                        review_status: ${params.status}
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	changeCouponStatus(axios, params) {
        let body = {
            query: `
                mutation {
                    changeCouponStatus(
                        id: ${params.id},
                        is_active: ${params.status}
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
	changeStatusGiftCard(axios, params) {
        let body = {
            query: `
                mutation {
                    changeStatusGiftCard(
                        id: ${params.id},
                        status: ${params.status}
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }

    changeRouteStatus(axios, params) {
        let body = {
            query: `
                mutation {
                    changeRouteStatus(
                        route_id: ${params.id},
                        route_status: ${params.status}
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    changeDriverOrFleet(axios, params) {
        let body = {
            query: `
                mutation {
                    changeFleetAndDriverTodayOrders(
                        route_id: ${params.route_id},
                        current_fleet_id: ${params.current_fleet_id},
                        new_fleet_id: ${params.new_fleet_id},
                        driver_id: ${params.driver_id}
                    )
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    getChangeLogs(axios, params) {
        let body = {
            query: `
                query {
                    auditLogListCMS(
                        paginated: ${params.paginated !== undefined ? params.paginated : true}
                        limit: ${params.limit}
                        offset: ${params.offset}
                        event_type: "${params.eventType}"
                        sortBy: "${params.field}"
                        sortOrder: "${params.sort}"
                    ) {
                        list {
                            id
                            event_id
                            event_type
                            event_prev_data
                            event_new_data
                            event_triggered_by
                            createdAt
                        }
                        total
                    }
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
    generateDriverRoute(axios, routeID) {
        let body = {
            query: `
                query {
                    driverOrdersRouting(
                        route_id: ${routeID}
                    ) 
                }
            `
        }
        return axios.post('', JSON.stringify(body)).then(res => {
            return res
        }).catch(err => {
            return Promise.reject(err)
        })
    }
}   
let routeService = new RouteService()
export {routeService}