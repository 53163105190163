import { useContext, useEffect, useState } from 'react';
import { CommonContext } from '../../context/CommonContext';
import { useNavigate } from 'react-router-dom';
import { AREA_PREFIX, BANNER_IMG, BANNER_PREFIX, BANNER_TYPE, TOAST_MSG_TYPE } from '../../utils/Constants';
import { routeService } from '../../Services/RouteService';
import { useBannerForm } from '../../hooks/BannerHooks';
import { useCityList } from '../../hooks/RouteHooks';
// import Select from 'react-select';
import { Select } from 'antd';
import useAuth from '../../hooks/useAuth';
import { DatePicker,Select as AntSelect } from 'antd';
import moment from 'moment';
import ImageUpload from '../ImageUpload';
import { BANNER_LINK_TYPE } from '../../utils/Constants';
import { bannerService } from '../../Services/BannerService';

const initialState = {banner_id: 0, banner_title: "", banner_display_order:0, banner_image: {server: ""}, banner_link_type: "", banner_link_data: "", banner_type: "", short_desc: "", store_id: undefined, start_date: undefined, end_date: undefined, cat_pro_data: {menu: [], product: []}, dataLoaded: false,banner_display_cities:[],all_city_display:0}
const BannerForm = (props) => {
    const navigate = useNavigate()
	let { auth, userInfo } = useAuth()
    const { showToast, showPromiseToast, axiosPrivate } = useContext(CommonContext)
    const [params, setParams] = useState({store: 0})
    const {data, loading} = useBannerForm(axiosPrivate, params)
    const [formState, setFormState] = useState({...initialState})
    const [disabled, setDisabled] = useState(false)
    const [showLoader, setShowLoader] = useState(true)
	const [sendEmail,setSendEmail]= useState(true)
	const [queryParam, setQueryParam] = useState({paginated: false, limit: 10, offset: 0, search: "", field: "area_name", sort: "ASC"})
    const {citiesData} = useCityList(axiosPrivate, queryParam) 
    useEffect(() => {
        if(props.banner_id && data.storeData.length && !formState.dataLoaded) {
            bannerService.getBannerDetailByID(axiosPrivate, props.banner_id).then(response => {
                if(response.data.bannerDetailsById) {
                    const res = response.data.bannerDetailsById
					if(res.all_city_display!==1){
						setSendEmail(false)
					}
                    setFormState((prev) => {
                        return {
                            ...prev,
                            banner_id: res.id,
                            banner_title: res.banner_title,
                            banner_display_order: res.banner_display_order,
                            start_date: res.start_date && moment(res.start_date),
                            end_date: res.end_date && moment(res.end_date),
                            banner_image: {server: res.banner_image ? res.banner_image : ""},
                            banner_type: res.banner_type,
                            banner_link_type: res.banner_link_type,
                            banner_link_data: res.banner_link_data,
                            store_id: res.store_id,
                            cat_pro_data: {...res.cat_pro_data},
							banner_display_cities: res.banner_display_cities,
                            dataLoaded: true
                        }
                    })
                    setParams((prev) => {
                        return {
                            ...prev,
                            store: res.store_id
                        }
                    })
                    setShowLoader(false)
                }else {
                    navigate(`${BANNER_PREFIX}`)
                }
            })    
        }else if(props.banner_id == undefined || props.banner_id == "0"){
            setShowLoader(false)
        }
    }, [props.banner_id, data.storeData])
    
    useEffect(() => {
        if(props.crud === "read") {
            setDisabled(true)
        }else {
            setDisabled(false)
        }
        
    }, [props.crud])

    const onChangeField = (e) => {
        const name = e.target.name
        const value = e.target.value
        setFormState({...formState, [name]: value})
    }

    const onChangeStore = (value) => {
        setParams({...params, store: value})
        setFormState({...formState, store_id: value, cat_pro_data: {menu: [], product: []}})
    }

    const onChangeBannerType = (value) => {
        setFormState({...formState, banner_type: value, cat_pro_data: {...formState.cat_pro_data, menu: []}})
    }

    const onChangeBannerLinkType = (value) => {
        setFormState({...formState, banner_link_type: value, banner_link_data: "", cat_pro_data: {...formState.cat_pro_data, product: []}})
    }

    const onChangeStartDate = (value, dateString) => {
        setFormState({...formState, start_date: moment(dateString)})
    }

    const onChangeEndDate = (value, dateString) => {
        setFormState({...formState, end_date: moment(dateString)})
    }

    const onChangeBannerMenu = (value) => {
        setFormState({...formState, cat_pro_data: {...formState.cat_pro_data, menu: [...value]}})
    }

    const onChangeProduct = (e) => {
        setFormState({...formState, cat_pro_data: {...formState.cat_pro_data, product: [...e]}})
    }
	const onChangeDelivrableCity = (e) => {
        setFormState({...formState, banner_display_cities: [...e]})
    }
	const checkboxChanged = (e) => {
		var name = e.target.name
		if(name==='sendEmail'){		
		setSendEmail(!sendEmail)
		}
    }

    const submitHandler = (e) => {
        e.preventDefault()
        if(!formState.banner_title) {
            showToast("Please Fill Banner Title", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.store_id) {
            showToast("Please Select Store", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(isNaN(formState.banner_display_order)) {
            showToast("Please Fill Display Order Correctly", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.banner_type) {
            showToast("Please Select Banner Type", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.banner_link_type) {
            showToast("Please Select Banner Link Type", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(formState.banner_link_type == "External") {
            if(!formState.banner_link_data) {
                showToast("Please Fill Banner Link", TOAST_MSG_TYPE.ERROR)
                return
            }
        }else {
            if(!formState.cat_pro_data.product.length) {
                showToast("Please Select Products To Show", TOAST_MSG_TYPE.ERROR)
                return
            }
        }
        if(formState.banner_type == "Submenu") {
            if(!formState.cat_pro_data.menu.length) {
                showToast("Please Select Menu To Show", TOAST_MSG_TYPE.ERROR)
                return
            }
        }
        if(!formState.start_date) {
            showToast("Please Fill Start Date", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.end_date) {
            showToast("Please Fill End Date", TOAST_MSG_TYPE.ERROR)
            return
        }
        if(!formState.banner_image.server) {
            showToast("Please Choose Image For Banner", TOAST_MSG_TYPE.ERROR)
            return
        }
        let startTime = moment(formState.start_date).format("YYYY-MM-DD")
        let endTime = moment(formState.end_date).format("YYYY-MM-DD")
        if(startTime === "Invalid date") {
            showToast("Please select correct start date", TOAST_MSG_TYPE.ERROR)
            return false
        }
        if(endTime === "Invalid date") {
            showToast("Please select correct end date", TOAST_MSG_TYPE.ERROR)
            return false
        }
        if(formState.start_date > formState.end_date) {
            showToast("End Date should be greater than to Start Date", TOAST_MSG_TYPE.ERROR)
            return false
        }
        let paramData = {
            banner_id: formState.banner_id,
            banner_title: formState.banner_title,
            banner_display_order: formState.banner_display_order,
            banner_link_type: formState.banner_link_type,
            banner_link_data: formState.banner_link_data,
            banner_type: formState.banner_type,
            store_id: formState.store_id,
            start_date: formState.start_date !== "" ? moment(formState.start_date).format("YYYY-MM-DD") : "",
            end_date: formState.end_date !== "" ? moment(formState.end_date).format("YYYY-MM-DD") : "",
            cat_pro_data: {...formState.cat_pro_data},
			banner_display_cities: formState.banner_display_cities,
			all_city_display: sendEmail ? 1: 0
        }
        const apiPromise = bannerService.addUpdateBanner(axiosPrivate, paramData, formState.banner_image.server)
        showPromiseToast(apiPromise, "Please Wait...")
        apiPromise.then(res => {
            if(res.data.addUpdateBanners) {
                showToast(props.crud === "create" ? "Banner Added Successfully!" : "Banner Updated Successfully", TOAST_MSG_TYPE.SUCCESS)
                setFormState({...initialState})
                navigate(`${BANNER_PREFIX}`)
            }
        })
    }

    return (
        <>
        {
            showLoader ? <p>Loading...</p> :
            <form>
                <div className='row mb-4'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Store*</label>
                            <Select 
                                showSearch={true}
                                className='select col-md-12'
                                value={formState.store_id}
                                options={data.storeData}
                                onChange={onChangeStore}
                                optionFilterProp={"store_name"}
                                fieldNames={{label: "store_name", value: "store_id"}}
                            />
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Banner Name*</label>
                            <input type="text" className='form-control' name='banner_title' value={formState.banner_title} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
					
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Display Order*</label>
                            <input type="text" className='form-control' name='banner_display_order' value={formState.banner_display_order} onChange={onChangeField} disabled={disabled}/>
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Start Date*</label>
                            <DatePicker 
                                placeholder="Select Start Date" 
                                className="form-control" 
                                onChange={onChangeStartDate} 
                                value={formState.start_date || ""}
                                disabledDate={(current) => {
                                    return moment().add(-1, 'days')  >= current ||
                                         moment().add(1, 'month')  <= current;
                                    }}
                            />
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>End Date*</label>
                            <DatePicker 
                                placeholder="Select End Date" 
                                className="form-control" 
                                onChange={onChangeEndDate} 
                                value={formState.end_date || ""}
                                disabledDate={(current) => {
                                    return moment().add(-1, 'days')  >= current
                                    }}
                            />
                        </div>
                    </div>
                    <div className='col-md-3'>
                        <div className='form-group'>
                            <label>Banner Image</label>
                            <ImageUpload
                                fileName={formState.banner_image.server}
                                fileList={
                                formState.banner_image.server
                                    ? [
                                        {
                                        uid: formState.banner_image,
                                        url:"local" in formState.banner_image ? `${formState.banner_image.local.url}` : `${BANNER_IMG}/${formState.banner_image.server}`
                                        }
                                    ]
                                    : []
                                }
                                getFile={file => {
                                    setFormState({...formState, banner_image: file});
                                }}
                                onRemove={() => setFormState({...formState, banner_image: {server: ""}})}
                                crud={props.crud}
                            />
                        </div>
                    </div>
					<div className='col-md-2'>
						<div className='form-group'>
						<input type="checkbox"  style={{marginRight: "10px"}} checked={sendEmail} onChange={checkboxChanged} name="sendEmail"/>						
						<label htmlFor="notification_flag">Display To All Store Cities ?</label>
						</div>
					</div>
					{
						!sendEmail &&
						<div className='col-md-12'>
							<div className='form-group'>
								<label>Select Display Cities</label>
								<AntSelect
										className="select col-md-12"
										value={formState.banner_display_cities}
										mode='multiple'
										options={userInfo.account_type == 1 ? citiesData : userInfo.merchant_delivery_city}
										loading={loading}
										loadingMessage={"Loading Cities"}
										name="banner_display_cities"
										onChange={onChangeDelivrableCity}
										isDisabled={disabled}
										optionFilterProp={"area_name"}
										fieldNames={{label: "area_name", value: "area_name"}}
									>
								</AntSelect>
							</div>
						</div>
					}
                </div>
				
                <div className='row mb-4'>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Banner Type*</label>
                            <Select 
                                showSearch={true}
                                className='select col-md-12'
                                value={formState.banner_type}
                                options={BANNER_TYPE}
                                onChange={onChangeBannerType}
                            />
                            {/* <Select 
                                className="select" 
                                value={formState.banner_type} 
                                options={BANNER_TYPE}
                                isLoading={loading}
                                name="store" 
                                onChange={(e) => onChangeBannerType(e)} 
                                isDisabled={disabled}
                                getOptionValue={(option) => `${option.label}`}
                                getOptionLabel={(option) => `${option.value}`}
                            >
                            </Select> */}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Banner Link Type*</label>
                            <Select 
                                showSearch={true}
                                className='select col-md-12'
                                value={formState.banner_link_type}
                                options={BANNER_LINK_TYPE}
                                onChange={onChangeBannerLinkType}
                            />
                            {/* <Select 
                                className="select" 
                                value={formState.banner_link_type} 
                                options={BANNER_LINK_TYPE}
                                isLoading={loading}
                                name="store" 
                                onChange={(e) => onChangeBannerLinkType(e)} 
                                isDisabled={disabled}
                                getOptionValue={(option) => `${option.label}`}
                                getOptionLabel={(option) => `${option.value}`}
                            >
                            </Select> */}
                        </div>
                    </div>
                </div>
                <div className='row mb-4'>
                {
                    formState.banner_type !== "Home" &&
                    <div className='col-md-6'>
                        <div className='form-group'>
                            <label>Select Banner Menu*</label>
                            <Select 
                                showSearch={true}
                                className='select col-md-12'
                                value={formState.cat_pro_data.menu}
                                options={data.menuData}
                                mode='multiple'
                                onChange={onChangeBannerMenu}
                                optionFilterProp={"category"}
                                fieldNames={{label: "category", value: "menu_item_id"}}
                            />
                            {/* <Select 
                                className="select" 
                                isMulti={true}
                                value={formState.cat_pro_data.menu} 
                                options={data.menuData}
                                isLoading={loading}
                                name="menu" 
                                onChange={(e) => onChangeBannerMenu(e)} 
                                isDisabled={disabled}
                                getOptionValue={(option) => `${option.menu_item_id}`}
                                getOptionLabel={(option) => `${option.category}`}
                            >
                            </Select> */}
                        </div>
                    </div>
                }
                {
                    (() => {
                        if(formState.banner_link_type == "External") {
                            return (
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>Banner Link*</label>
                                        <input type="text" placeholder='https://' className='form-control' name='banner_link_data' value={formState.banner_link_data} onChange={onChangeField} disabled={disabled}/>
                                    </div>
                                </div>
                            )
                        }else if(formState.banner_link_type == "Internal") {
                            return (
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>Select Products*</label>
                                        <Select 
                                            showSearch={true}
                                            className='select col-md-12'
                                            value={formState.cat_pro_data.product}
                                            options={data.productData}
                                            mode='multiple'
                                            onChange={onChangeProduct}
                                            optionFilterProp={"product_title"}
                                            fieldNames={{label: "product_title", value: "product_id"}}
                                        />
                                        {/* <Select 
                                            className="select" 
                                            isMulti={true}
                                            value={formState.cat_pro_data.product} 
                                            options={data.productData}
                                            isLoading={loading}
                                            name="menu" 
                                            onChange={(e) => onChangeProduct(e)} 
                                            isDisabled={disabled}
                                            getOptionValue={(option) => `${option.product_id}`}
                                            getOptionLabel={(option) => `${option.product_title}`}
                                        >
                                        </Select> */}
                                    </div>
                                </div>
                            )
                        }
                    })()
                }
                </div>
                <div className='row'>
                    <div className='col-md-12'>
                        <button className="btn btn-primary btn-width mx-auto d-block" onClick={submitHandler}>Save</button>
                    </div>
                </div>
            </form>
        }
        </>
    )
}

export default BannerForm